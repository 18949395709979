<template>
  <el-dialog
    v-model="winShow"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    @close="closeWin">
    <div class="form">
      <el-form
        ref="form"
        :loading="formLoading"
        :model="formData.form"
        :rules="formData.rules"
        label-width="170px"
        label-position="right">
        <!-- 选择产品 -->
        <el-form-item :label="t('product.create_select_pro')" prop="product_id">
          <el-select
            v-model="formData.form.product_id"
            :disabled="!!edit_id"
            @change="getSaasName" filterable
            clearable
            :placeholder="t('form.selectPlaceholder')">
            <el-option
              v-for="(item, index) in productList"
              :key="index"
              :label="item.label"
              :value="item"
              value-key="value" />
          </el-select>
        </el-form-item>
        <!-- 项目名称 -->
        <el-form-item :label="t('project.project_name')" prop="name">
          <el-input v-model="formData.form.name" :disabled="disableName" :placeholder="t('form.inputPlaceholder')" />
        </el-form-item>
        <!-- 项目邮箱 -->
        <el-form-item :label="t('project.project_mail')" prop="mail">
          <el-input v-model="formData.form.mail" :placeholder="t('form.inputPlaceholder')" />
        </el-form-item>
        <!-- 语言 -->
        <el-form-item :label="t('system.language')" prop="language">
          <el-select
            v-model="formData.form.language"
            filterable
            :placeholder="t('form.selectPlaceholder')"
            :disabled="!!edit_id">
            <el-option key="zh" :label="t('system.zh')" value="zh"></el-option>
            <el-option key="en" :label="t('system.en')" value="en"></el-option>
          </el-select>
        </el-form-item>
        <!-- 时区 -->
        <el-form-item :label="t('system.time_zone_project')" prop="time_zone">
          <el-select
            v-model="formData.form.time_zone"
            filterable
            :placeholder="t('form.selectPlaceholder')">
            <el-option v-for="(item, index) in timeZoneList" :label="item.name" :value="item.id" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <!-- 企业PO号 -->
        <el-form-item :label="t('project.customer_po')" prop="customer_po">
          <el-input v-model="formData.form.customer_po" :placeholder="t('form.inputPlaceholder')" />
        </el-form-item>
        <!-- 产品用户名 -->
        <el-form-item v-if="hiddenSaasName" :label="t('product.user_product')">
          <el-input
            v-model="formData.form.saas_name"
            :disabled="!!edit_id"
            :placeholder="t('form.inputPlaceholder')"
            @blur="getLicKey" />
        </el-form-item>
        <!-- 注册码 -->
        <el-form-item :label="t('project.license')">
          <el-input
            v-model="formData.form.lic_key"
            :rows="6"
            :disabled="disabledLicKey"
            :placeholder="t('form.inputPlaceholder')"
            type="textarea" />
        </el-form-item>
        <!-- 回切注册码 -->
        <el-form-item v-if="hiddenFailBackName" :label="t('project.failback_key')">
          <el-switch v-model="formData.form.hiddenFail"></el-switch>
        </el-form-item>
        <el-form-item v-if="formData.form.hiddenFail" label="">
          <el-input
            type="textarea"
            v-model="formData.form.failback_key"
            :rows="6"
            :disabled="disabledFailbackKey"
            :placeholder="t('form.inputPlaceholder')" />
        </el-form-item>
        <!-- 备注 -->
        <el-form-item :label="t('user.notes')">
          <el-input
            type="textarea"
            v-model="formData.form.comment"
            :rows="6"
            :placeholder="t('form.inputPlaceholder')" />
        </el-form-item>
        <!-- 项目状态 -->
        <el-form-item :label="t('project.status')">
          <el-switch
            v-model="formData.form.effectuate"
            :active-text="t('project.enabled')"
            :inactive-text="t('form.close')"></el-switch>
        </el-form-item>
        <!-- 是否分配子用户 -->
        <el-form-item :label="t('project.assign_sub_user')">
          <el-switch v-model="formData.form.is_child" @change="getSubuserList"></el-switch>
        </el-form-item>
        <!-- 选择子用户 -->
        <el-form-item v-if="formData.form.is_child" :label="t('project.select_sub_user')" prop="sub_user_id">
          <el-select
            v-model="formData.form.sub_user_id"
            filterable
            :placeholder="t('form.selectPlaceholder')">
            <el-option v-for="(item, index) in subUserList" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <!-- 只有编辑的时候显示 -->
         <!-- 华为云授权码 -->
        <el-form-item v-if="!!edit_id" :label="t('project.huawe_cloud_lic')" prop="cloud_license">
          <el-input
            v-model.tirm="formData.form.cloud_license"
            :placeholder="t('form.inputPlaceholder')"
            @input="trimInput('cloud_license')" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="onSubmit" type="primary">提交</el-button>
        <el-button @click="closeWin">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import store from '@/store'
import { ref, inject, computed } from 'vue'
import { getUserList, getEnterpriseProductList } from '@/utils/common'
import { AuthManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'
import { timeZones } from '@/utils/timeZones'

const t = inject('t')
const emit = defineEmits(['getList'])
const form = ref()
const disableName = ref(false) // 是否禁止编辑项目名称
const hiddenSaasName = ref(false) // 显示或隐藏产品用户名
const hiddenFailBackName = ref(false) // 显示或隐藏回切注册码
const disabledFailbackKey = ref(false) // 禁止输入回切注册码
const disabledLicKey = ref(false) // 禁止输入注册码
const winShow = ref(false)
const edit_id = ref('')
const productList = ref([])
const subUserList = ref([])
const saasInfo = ref()
const formLoading = ref(false) // 表单loading
const formData = ref({
  form: {
    is_child: false,
    lic_key: '',
    effectuate: true,
    language: 'zh',
    cloud_license: '',
    customer_po: ''
  },
  rules: {
    product_id: [{ required: true, message: t('form.emptyErrMsg') }],
    name: [{ required: true, message: t('form.emptyErrMsg') }],
    sub_user_id: [{ required: true, message: t('form.emptyErrMsg') }],
    language: [{ required: true, message: t('form.emptyErrMsg') }],
    time_zone: [{ required: true, message: t('form.emptyErrMsg') }],
    mail: [
      { required: true, message: t('form.emptyErrMsg') },
      { type: 'email', message: t('form.error_mail') }
    ],
    cloud_license: [{
      required: false,
      trigger: 'blur',
      validator: (rule, value, callback) => {
        if (value && !/^[a-zA-Z0-9]+$/.test(value)) {
          callback(new Error(t('form.must_be_number_letter')))
        } else {
          callback()
        }
      }
    }]
  }
})

const timeZoneList = computed(() => {
  const list = JSON.parse(JSON.stringify(timeZones))
  list.forEach(item => {
    const matches = item.name.match(/\(GMT([+-]\d{2}:\d{2})\)/)
    item.label = `GMT${matches ? matches[1] : ''}`
  })
  return list
}) // 时区列表
// 去除空格
const trimInput = (key) => {
  formData.value.form[key] = formData.value.form[key].trim()
}
const title = computed(() => {
  return !!edit_id.value ? t('project.edit_project') : t('project.create_project')
})
// 产品改变触发 - 获取saasname
const getSaasName = (data) => {
  formData.value.form.lic_key = ''
  disabledLicKey.value = false
  formData.value.form.saas_name = ''
  const items = productList.value.find(item => {
    return item.value === data.value
  })
  // 非单机版隐藏产品用户名
  hiddenSaasName.value = items.product.deployment.id === 1002 ? false : (items.product.params && items.product.params.auth_address) ? true : false
  // saas版本隐藏回切注册码
  hiddenFailBackName.value = items.product.category.id === 1002
}

// 设置lic_key
const getLicKey = () => {
  if (!!!formData.value.form.product_id) return
  if (!!!formData.value.form.saas_name) return
  getSaasinfo()
}

// 根据产品用户名获取产品注册码
const getSaasinfo = async () => {
  formLoading.value = true
  try {
    const res = await AuthManagement.ProjectLicKey(formData.value.form.product_id.value, formData.value.form.saas_name)
    saasInfo.value = res
    // 是否禁止编辑注册码
    disabledLicKey.value = res.lic_key ? true : false
    formData.value.form.lic_key = res.lic_key
    // 是否禁止编辑回切注册码
    formData.value.form.failback_key = res.failback_key
    disabledFailbackKey.value = res.failback_key ? true : false
    formLoading.value = false
  } catch (error) {
    // 清空
    disabledLicKey.value = false
    formData.value.form.lic_key = ''
    formData.value.form.failback_key = ''
    disabledFailbackKey.value = false
    formLoading.value = false
  }
}

// 获取子用户列表数据
const getSubuserList = async (value) => {
  formData.value.form.sub_user_id = ''
  if (value) subUserList.value = await getUserList({ filter_role_alias: 'Subordinate' })
}

// 编辑绑定数据
const bindData = (data) => {
  disableName.value = !Boolean(data.params?.modifiable) // 是否禁止编辑项目名称
  formData.value.form.product_id = {
    ...data.product,
    label: data.product?.name,
    value: data.product?.id
  }
  disabledLicKey.value = data.lic_key ? true : false
  // 是否显示回切注册码
  if (data.product?.category?.id === 1002) {
    hiddenFailBackName.value = true
    formData.value.form.failback_key = data.failback_key
    disabledFailbackKey.value = data.failback_key ? true : false
    formData.value.form.hiddenFail = data.params.failback_switch
  }
  // 存在saas_name
  if (data.params?.saas_name) {
    formData.value.form.saas_name = data.params.saas_name
    hiddenSaasName.value = true
  }
  // 语言设置
  formData.value.form.language = data.params.language ? data.params.language : 'zh'
  // 企业PO号
  formData.value.form.customer_po = data.params.customer_po || ''
  // 时区
  formData.value.form.time_zone = data.params.time_zone || ''
  // 表单绑值
  const keySet = new Set(['comment', 'lic_key', 'effectuate', 'params'])
  Object.keys(formData.value.rules).forEach(key => keySet.add(key))
  Object.keys(data).forEach(i => {
    if (keySet.has(i)) formData.value.form[i] = data[i]
  })
  // 存在子用户
  if (data.sub_user) {
    formData.value.form.is_child = true
    formData.value.form.sub_user_id = data.sub_user.id
  }
}

// 打开弹框
const openWin = async (data) => {
  winShow.value = true
  // 获取企业产品列表
  productList.value = await getEnterpriseProductList(store.state.users.enterprise_id)
  edit_id.value = data ? data.id : ''
  if (data) {
    if (data.sub_user) getSubuserList(true)
    bindData(data)
  }
}

// 提交表单
const onSubmit = async () => {
  if (!form.value) return
  form.value.validate(valid => {
    if (!valid) return
    let subData = {
      ...formData.value.form,
      product_id: formData.value.form.product_id.value,
      enterprise_id: store.state.users.enterprise_id,
      params: {
        ...formData.value.form.params,
        modifiable: false,
        saas_name: formData.value.form.saas_name,
        language: formData.value.form.language,
        failback_switch: formData.value.form.hiddenFail,
        customer_po: formData.value.form.customer_po,
        time_zone: formData.value.form.time_zone,
        display_time_zone: timeZones.find(item => item.id === formData.value.form.time_zone)?.name
      }
    }
    // 新增
    if (saasInfo.value) {
      subData = {
        ...subData,
        saas_id: saasInfo.value.saas_id,
        params: {
          ...subData.params,
          saas_ent_id: saasInfo.value.params?.ent_id ?? ''
        }
      }
    }
    // 只有编辑的时候传递cloud_license参数
    if (edit_id.value) {
      subData.cloud_license = formData.value.form.cloud_license
    }
    const action = edit_id.value 
      ? () => AuthManagement.ProjectUpdate(edit_id.value, subData)
      : () => AuthManagement.ProjectCreate(subData);

    action().then(res => {
      ElMessage.success(edit_id.value ? t('table.modify_success') : t('table.add_success'))
      emit('getList')
      closeWin()
    })
  })
}

const closeWin = () => {
  form.value.resetFields()
  formData.value.form = {}
  disableName.value = false
  hiddenSaasName.value = false
  hiddenFailBackName.value = false
  edit_id.value = ''
  winShow.value = false
}

defineExpose({
  openWin: openWin
})
</script>